<template>
  <v-app-bar
    absolute
    color="transparent"
    flat
    height="85"
  >
    <v-container class="px-0 text-right d-flex align-center">
      <v-img
        :src="require(`@/my/assets/logo.png`)"
        contain
        max-height="36"
        max-width="165"
      />

      <v-spacer />

      <v-btn
        v-for="(item, i) in items"
        :key="i"
        :to="item.to"
        min-height="48"
        min-width="40"
        text
        class="mr-2"
      >
        <v-icon
          :left="$vuetify.breakpoint.mdAndUp"
          size="20"
          v-text="item.icon"
        />

        <span
          class="hidden-sm-and-down ml-1"
          v-text="$tc(item.text, 1)"
        />
      </v-btn>
      <v-speed-dial
        v-model="locale"
        direction="bottom"
        transition="slide-y-transition"
      >
        <template #activator>
          <v-btn
            v-model="locale"
            fab
            small
            class="mb-1"
          >
            <v-icon v-if="locale">
              mdi-close
            </v-icon>
            <span v-else>
              {{ $i18n.locale }}
            </span>
          </v-btn>
        </template>
        <v-btn
          v-for="(l, i) in $store.state.locales"
          :key="'L'+i"
          fab
          x-small
          :class="{ 'primary': $i18n.locale === l.value }"
          @click="$store.commit('SET_LOCALE', l.value); $i18n.locale = l.value; $vuetify.lang.current = l.value"
        >
          {{ l.value }}
        </v-btn>
      </v-speed-dial>
    </v-container>
  </v-app-bar>
</template>

<script>
  export default {
    name: 'PagesCoreAppBar',

    data: () => ({
      items: [
        {
          icon: 'mdi-account-multiple-plus',
          text: 'signup',
          to: '/signup',
        },
        {
          icon: 'mdi-fingerprint',
          text: 'login',
          to: '/',
        },
      ],
      titles: {
        '/pages/register': 'Register Page',
        '/': 'Login Page',
      },
      locale: false,
    }),

    computed: {
      title () {
        return this.titles[this.$route.path]
      },
    },
  }
</script>
